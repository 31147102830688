import PouchDB from 'pouchdb-browser'
PouchDB.adapter('worker', require('worker-pouch'))

export default {
  data() {
    return {
      remoteEFormSurvey: null,
      saveQuestion: null,
      savePixel: null,
    }
  },
  methods: {
    async getEForm() {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/get-eform`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      await this.$axios
        .get(endpoint, config)
        .then(response => {
          if (response.data.status) {
            response.data.data.forEach(data => {
              data.formatted_created_at = this.$moment.unix(data.created_at).format('DD/MM/YYYY HH:mm:ss')
            })

            this.eForm = response.data.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async createEForm(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/create-eform`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      await this.$axios
        .post(endpoint, data, config)
        .then(response => {
          if (response.data.status) {
            this.$router.push({ name: 'e-form' }, () => {})
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getEFormById(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/get-eform-by-id`

      await this.$axios
        .post(endpoint, data)
        .then(response => {
          // console.log(response)
          if (response.data.status) {
            if (response.data.data) {
              this.eForm = response.data.data
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    async updateContactEform(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/add-or-update-contact-survey`

      await this.$axios
        .post(endpoint, data)
        .then(response => {
          console.log(response)
          // if (response.data.status) {
          //   if (response.data.data) {
          //     this.eForm = response.data.data
          //   }
          // }
        })
        .catch(error => {
          console.log(error)
        })
    },
    // eform survey
    async connectEFormSurvey() {
      const host = `${process.env.VUE_APP_COUCH_DB_DIRECT_URL}/eform_survey`
      const remote = new PouchDB(host, {
        fetch(url, opts) {
          opts.headers.set('Content-Type', 'application/json')

          return PouchDB.fetch(url, opts)
        },
      })

      this.remoteEFormSurvey = remote
    },
    async saveEFormSurvey(data) {
      this.saveQuestion = data
    },
    async onCompleteSurveyNoParams(data) {
      const { id } = this.$route.params
      let subId = id.split('-')
      // let nameAfter = decodeURI(name)
      let tokenId = this.$nanoid()
      let obj = {}
      this.eForm.survey.pages.forEach(el => {
        el.elements.forEach(item => {
          if (data.hasOwnProperty(item.name)) {
            if (item.hasOwnProperty('title')) {
              obj[item.title] = data[item.name]
            } else {
              obj[item.name] = data[item.name]
            }
          }
        })
      })
      const payload = {
        _id: tokenId,
        eform_survey_id: 'none',
        contactList: this.eForm.contactList,

        answerData: obj,
        timestamp: Date.now(),
        sub_id: subId[0],
      }

      await this.remoteEFormSurvey
        .put(payload)
        .then(async response => {
          let allQuestion = []

          let allText = ``

          this.saveQuestion.survey.pages.forEach(el => {
            el.elements.forEach(item => {
              allQuestion.push(item)
              let answer = '-'
              let question = ''
              if (data.hasOwnProperty(item.name)) {
                answer = data[item.name]
              }
              if (item.hasOwnProperty('title')) {
                question = '*' + item.title + '*'
              } else {
                question = '*' + item.name + '*'
              }

              let textUse = `
${question}
${answer}
`
              allText += textUse
            })
          })
          const message = `${this.saveQuestion.title}
--------------------------
${this.saveQuestion.description}

${allText}

--------------------------
No Ticket : ${tokenId}
Form ID : ${id}
--------------------------
`
          const encoded = encodeURI(`https://wa.me/${this.eForm.channel}?text=${message}`)
          window.open(encoded, '_blank')
        })
        .catch(error => {
          console.log(error)

          this.$dialog.message.error('Failed to complete survey')
        })
    },
    async onCompleteSurvey(data) {
      const { id, phone, name } = this.$route.params
      let subId = id.split('-')
      let nameAfter = decodeURI(name)

      console.log(data, 'INI DATA')

      const payload = {
        _id: this.$nanoid(),
        eform_survey_id: this.eFormSurveyId,
        contactList: this.eForm.contactList,
        ...data,
      }

      await this.remoteEFormSurvey
        .put(payload)
        .then(async response => {
          await this.updateContactEform({
            sub_id: subId[0],
            phone_number: phone,
            name: nameAfter,
            list_id: this.eForm.contactList,
            answerData: data,
          })
        })
        .catch(error => {
          console.log(error)

          this.$dialog.message.error('Failed to complete survey')
        })
    },
    // eform analytics
    async getEFormAnalytics(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/get-eform-analytics`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      await this.$axios
        .post(endpoint, data, config)
        .then(response => {
          if (response.data.status) {
            this.data = response.data.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
